import './App.css';
import Gallery from './imgs';
import React, { useState } from 'react';

function Slide(props) {
	let type="horizontal";
	if (props.slide.direction == "up" || props.slide.direction == "down")
		type="vertical";
	return <div className={"slide " + type + " " + props.slide.direction}>
			<div className="bg">
				<img src={props.slide.img} />
				<div className="mask"></div>
			</div>
			<div className="content">
				<div className="box">
				{ props.slide.context && <div className="context">{props.slide.context}</div> }
					<div className="quote">{props.slide.quote}</div>
					<div className="author">{props.slide.author}</div>
				</div>
			</div>
		</div>
}

const Dirs = ["left", "right", "up", "down"];

function App() {
	
	const [data, setData] = useState([]);
	const [slides, setSlides] = useState([]);
	
	const genSlide = () => {
		const q = Math.floor(Math.random() * data.length);
		const i = Math.floor(Math.random() * Gallery.length);
		const d = Math.floor(Math.random() * Dirs.length);
		if (data.length)
			setSlides(slides => [...((slides.length > 1)? slides.slice(1) : slides), { 
				key: new Date() + Math.random(),
				quote: data[q].text,
				author: data[q].author,
				context: data[q].context,
				direction: Dirs[d],
				img: Gallery[i]
			}]);
	};
	const hookInterval = () => { clearInterval(window.interval);
 window.interval = setInterval(() => {
				genSlide();
		}, 10000);
	}
	
	React.useEffect(() => {
		genSlide();
		hookInterval();
	}, [data]);
	
	React.useEffect(() => {
	  fetch(process.env.REACT_APP_FETCH)
		.then((res) => res.json())
		.then((d) => { 
			setData(d);
		});	
		return () => {
			clearInterval(window.interval);
		};
	}, []);
	
	return (
		<div className="App" onClick={() => { genSlide(); clearInterval(window.interval); hookInterval(); }}>
			{slides.map((slide) => <Slide key={slide.key} slide={slide} />)}
			<div style={{display:"none"}}>{ Gallery.map((i, k) => <img src={i} key={k} />) }</div>
		</div>
	);
}
export default App;
